import Api from '@/apis/index.js'

export default class Server extends Api {
  getWxSettingInfos () {
    return this.$http.get(`${Api.path.apiCrmOS}partner/${window.global._auth.userInfo.partnerId}/grantinfo`)
  }

  postWxSetingInfo (type, o) {
    return this.$http.post(`${Api.path.apiCrmOS}partner/${window.global._auth.userInfo.partnerId}/type/${type}/grantinfos`, o)
  }

  getMessageInfo (o) {
    return this.$http.get(`${Api.path.apiMps}partner/${window.global._auth.userInfo.partnerId}/weixin-auth-config/template`, { params: o })
  }

  postMessageInfo (o) {
    return this.$http.post(`${Api.path.apiMps}partner/${window.global._auth.userInfo.partnerId}/weixin-auth-config/template`, o)
  }

  getPreview (o) {
    return this.$http.get(`${Api.path.apiMps}partner/${window.global._auth.userInfo.partnerId}/weixin-auth-config/template/preview`, { params: o })
  }

  deleteConfig (types) {
    return this.$http.delete(`${Api.path.apiCrmOS}partner/${window.global._auth.userInfo.partnerId}/grantinfo?typeIds=${types}`)
  }

  deleteMessageTemplate () {
    return this.$http.delete(`${Api.path.apiMps}partner/${window.global._auth.userInfo.partnerId}/weixin-auth-config/template`)
  }
}
