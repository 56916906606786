<template>
	<div class="mod-wx-setting normal-content">
		<div class="setting-container">
			<el-steps :active="currentStep" align-center>
				<el-step>
					<h5 slot="title" class="setting-container-title">
						公众号授权
						<el-popover
							v-if="active === -1"
							placement="top"
							width="320"
							trigger="hover"
							content="授权企业的公众号，网页授权登录"
							>
							<i v-if="active === -1" slot="reference" class="el-icon-question" />
						</el-popover>
						<i class="title-icon"><img alt="" src="@/assets/imgs/wx-setting.png"></i>
					</h5>
					<el-button
						v-if="active === -1"
						slot="description"
						:class="[active=== -1 && status[0] ? 'setted':'', active === 0 ? 'view-doc':'']"
						:disabled="active === -1 && !status[0]"
						:type="active === 0 ? 'primary': 'text'"
						:plain="active === 0 ? true: false"
						@click="onViewDoc(active === 0,'/#/developers-doc/wx')"
						>{{ active === 0 ? '查看配置文档':status[0] ? '已设置':'未设置' }}</el-button>
				</el-step>
				<el-step>
					<h5 slot="title" class="setting-container-title">
						配置微信支付
						<el-popover
							v-if="active === -1"
							placement="top"
							width="320"
							trigger="hover"
							content="打通微信支付功能，通过公众号发红包给销售人员，实现佣金的实时到账能力"
							>
							<i v-if="active === -1" slot="reference" class="el-icon-question" />
						</el-popover>
						<i class="title-icon"><img alt="" src="@/assets/imgs/wx-pay.png"></i>
					</h5>
					<el-button
						v-if="active === -1"
						slot="description"
						:class="[active=== -1 && status[3] ? 'setted':'', active === 3 ? 'view-doc':'']"
						:disabled="active === -1 && !status[3]"
						:type="active === 3 ? 'primary': 'text'"
						:plain="active === 3 ? true: false"
						@click="onViewDoc(active === 3,'/#/developers-doc/wxpay')"
						>{{ active === 3 ? '查看配置文档':status[3] ? '已设置':'未设置' }}</el-button>
				</el-step>
				<el-step>
					<h5 slot="title" class="setting-container-title">
						App授权
						<el-popover
							v-if="active === -1"
							placement="top"
							width="320"
							trigger="hover"
							content="配置用户运营系统域名，实现分享的文章内容可通过公众号授权用户信息，掌握微信访客的访问动态；同时设置好工作台入口，方便员工快速登录"
							>
							<i v-if="active === -1" slot="reference" class="el-icon-question" />
						</el-popover>
						<i class="title-icon"><img alt="" src="@/assets/imgs/dns-icon.png"></i>
					</h5>
					<!-- v-if="active === -1 || active === 5" -->
					<el-button
						v-if="active === -1"
						slot="description"
						:class="[active=== -1 && status[5] ? 'setted':'', active === 5 ? 'view-doc':'']"
						:disabled="active === -1 && !status[5]"
						:type="active === 5 ? 'primary': 'text'"
						:plain="active === 5 ? true: false"
						@click="onViewDoc(active === 5,'/#/developers-doc/wx')"
						>{{ active === 5 ? '查看配置文档':status[5] ? '已设置':'未设置' }}</el-button>
				</el-step>
			</el-steps>
			<div v-if="active === -1" class="wx-bind-information">
				<div class="wx-bind-information-area">
					<h6 class="title">授权之前，请确认：</h6>
					<ul class="list">
						<li class="item">1、您有一个微信认证的服务号；</li>
						<li class="item">2、保证公众号中业务域名预留2个位置，如缺少则需清除。</li>
						<li class="item">3、保证微信支付商户号已入住90日，且至今有连续30天的交易，否则无法绑定；</li>
						<li class="item">注意：一个公众号只能绑定一个企业，否则将对业务造成影响。</li>
					</ul>
				</div>
				<el-button type="primary" @click="onShouldSetting">立即授权</el-button>
			</div>
			<div v-if="active ===0" class="wx-form-information">
				<jw-form ref="information" class="wx-form-information-form" v-bind="information" />
				<p class="button-item">
					<el-button type="primary" @click="onSaveInformation">下一步</el-button>
					<el-button @click="active = -1">取消</el-button>
					<el-button type="text" @click="onGotoNext">跳过此步骤</el-button>
					<el-button class="clear-button" type="text" @click="onClear">清除所有配置</el-button>
				</p>
			</div>
			<div v-if="active === 3" class="wx-form-wxpay">
				<jw-form ref="wxpay" class="wx-form-wxpay-form" v-bind="wxpay" />
				<p class="button-item">
					<el-button @click="onPrev">上一步</el-button>
					<el-button type="primary" @click="onSaveWxPay">下一步</el-button>
					<el-button type="text" @click="onGotoNext">跳过此步骤</el-button>
				</p>
			</div>

			<div v-if="active === 5" class="wx-form-wxpay">
				<app-auth ref="app" :form-data="formData" @complate="handlerComplte" />
				<p class="button-item">
					<el-button @click="onPrev">上一步</el-button>
					<el-button type="primary" @click="onSaveApp">保存</el-button>
					<el-button type="text" @click="onGotoNext">跳过并保存</el-button>
				</p>
			</div>
		</div>
	</div>
</template>

<script lang="jsx">
import Api from './apis.js'
import messageBox from './message-box.vue'
import AppAuth from './index-app.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    messageBox,
    AppAuth
  },
  data () {
    const self = this
    return {
      active: -1,
      formData: {
        weixinOpenAppId: null,
        weixinOpenName: null,
        weixinOpenAppSecret: null,
        weixinOpenFileName: null,
        weixinOpenFileContent: null,
        trace_msg_no: null,
        order_msg_no: null,
        weixinPaySendName: null,
        weixinPayMchid: null,
        weixinPaySecret: null,
        weixinMiniProgramAppId: null,
        weixinMiniProgramAppSecret: null,
        weixinMiniProgramOriginalId: null,
        openName: null,
        openAppId: null,
        openAppSecret: null
      },
      state: false,
      information: {
        read: false,
        bind: {
          domProps: {
            autocomplete: 'off'
          },
          props: {
            labelWidth: '182px',
            rules: {
              name: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              },
              appid: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              },
              appsecret: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              }
            }
          }
        },
        data: [{
          tag: 'title',
          label: '填写已经过认证的服务号信息'
        }, {
          label: ' 微信公众号名称',
          tag: 'el-input',
          prop: 'name',
          bind: {
            attrs: {
              placeholder: '请输入微信公众号名称',
              maxlength: 30
            }
          }
        }, {
          label: '开发者ID(AppID)',
          tag: 'el-input',
          prop: 'appid',
          bind: {
            attrs: {
              placeholder: '请输入开发者ID',
              maxlength: 100
            }
          }
        }, {
          label: '开发者密码(AppSecret)',
          prop: 'appsecret',
          render (h, store, value) {
            return <el-input
              value={value.appsecret}
              class='input-password'
              type='text'
              maxlength={100}
              onInput={(e) => { value.appsecret = e }} placeholder='请输入开发者密码'>
            </el-input>
          }
        }],
        value: {
          name: null,
          appid: null,
          appsecret: null
        }
      },
      wxpay: {
        read: false,
        bind: {
          domProps: {
            autocomplete: 'off'
          },
          props: {
            labelWidth: '142px',
            rules: {
              weixinPaySendName: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              },
              weixinPayMchid: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              },
              file: {
                required: true,
                trigger: 'blur',
                validator (rule, value, callback) {
                  if (value.length === 0) {
                    callback(new Error('不能为空！'))
                  } else {
                    callback()
                  }
                }
              },
              weixinPaySecret: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              }
            }
          }
        },
        data: [
          {
            tag: 'title',
            label: '微信支付信息设置'
          },
          {
            tag: 'el-input',
            label: '商户名称',
            prop: 'weixinPaySendName',
            bind: {
              props: {
                disabled: false
              },
              attrs: {
                placeholder: '请输入商户简称（建议6个字内）',
                maxlength: 10
              },
              on: {
                input (e) {
                  self.$refs.wxpay.$setValueField('weixinPaySendName', e)
                }
              },
              nativeOn: {
                compositionstart (e) {
                  self.state = true
                },
                compositionend (e) {
                  self.state = false
                  if (!self.state) {
                    e.target.value = e.target.value.replace(/\s+/gm, '')
                    self.$refs.wxpay.$setValueField('weixinPaySendName', e.target.value)
                  }
                },
                input (e) {
                  if (!self.state) {
                    e.target.value = e.target.value.replace(/\s+/gm, '')
                    self.$refs.wxpay.$setValueField('weixinPaySendName', e.target.value)
                  }
                }
              }
            }
          }, {
            tag: 'el-input',
            label: '商户号(MCHID)',
            prop: 'weixinPayMchid',
            bind: {
              props: {
                disabled: false
              },
              attrs: {
                placeholder: '请输入微信支付商户号',
                maxlength: 10
              },
              on: {
                input (e) {
                  self.$refs.wxpay.$setValueField('weixinPayMchid', e)
                }
              },
              nativeOn: {
                compositionstart (e) {
                  self.state = true
                },
                compositionend (e) {
                  self.state = false
                  if (!self.state) {
                    e.target.value = e.target.value.replace(/\s+/gm, '')
                    self.$refs.wxpay.$setValueField('weixinPayMchid', e.target.value)
                  }
                },
                input (e) {
                  if (!self.state) {
                    e.target.value = e.target.value.replace(/\s+/gm, '')
                    self.$refs.wxpay.$setValueField('weixinPayMchid', e.target.value)
                  }
                }
              }
            }
          },
          {
            tag: 'title',
            label: '微信支付API证书和密钥'
          }, {
            tag: 'jw-upload',
            label: '商户支付API证书',
            prop: 'file',
            bind: {
              props: {
                action: `${Api.path.apiCrmOS}partner/${self._auth.userInfo.partnerId}/type/4/upload-file?fileNameTitle=weixinPayFileName&fileValueTitle=weixinPayFileContent`,
                accept: 'application/x-pkcs12',
                acceptName: 'p12',
                hasName: true,
                defaultIcons: ['delete'],
                imgs: [],
                beforeTextTip () {
                  return '证书用于企业账号支付以及退款原路返回，请解压并使用拓展名为p12的证书文件'
                },
                max: 1,
                imgSize: 1,
                name: 'upfile',
                getImage (res, file, fileList) {
                  self.$refs.wxpay.$setValueField('file', [{ url: '', name: file.name }])
                  return {
                    url: '',
                    name: file.name
                  }
                },
                bind: {
                  listType: 'text',
                  disabled: true
                }
              },
              on: {
                success () {
                  self.$nextTick(() => {
                    self.$refs.wxpay.$validateField('file', (e) => {})
                  })
                }
              }
            }
          }, {
            tag: 'el-input',
            label: '商户支付密钥',
            prop: 'weixinPaySecret',
            bind: {
              props: {
                disabled: false
              },
              attrs: {
                placeholder: '请输入商户支付密钥',
                maxlength: 100
              },
              on: {
                input (e) {
                  self.$refs.wxpay.$setValueField('weixinPaySecret', e)
                }
              },
              nativeOn: {
                compositionstart (e) {
                  self.state = true
                },
                compositionend (e) {
                  self.state = false
                  if (!self.state) {
                    e.target.value = e.target.value.replace(/\s+/gm, '')
                    self.$refs.wxpay.$setValueField('weixinPaySecret', e.target.value)
                  }
                },
                input (e) {
                  if (!self.state) {
                    e.target.value = e.target.value.replace(/\s+/gm, '')
                    self.$refs.wxpay.$setValueField('weixinPaySecret', e.target.value)
                  }
                }
              }
            }
          }
        // {
        //   tag: 'title',
        //   label: '佣金到账规则'
        // }, {
        //   label: '微信支付到零钱',
        //   render (h, store, value) {
        //     return '线索分佣模式、订单分佣模式'
        //   }
        // }, {
        //   label: '微信现金红包',
        //   render (h, store, value) {
        //     return '访问分佣模式'
        //   }
        // }
        ],
        value: {
          weixinPaySendName: '',
          weixinPayMchid: '',
          file: [],
          weixinPaySecret: ''
        }
      }
    }
  },
  computed: {
    status: function () {
      return [
        this.formData.weixinOpenAppId && this.formData.weixinOpenName && this.formData.weixinOpenAppSecret,
        this.formData.weixinOpenFileName,
        this.formData.trace_msg_no && this.formData.order_msg_no,
        this.formData.weixinPaySendName && this.formData.weixinPayMchid && this.formData.weixinPaySecret,
        this.formData.weixinMiniProgramAppId && this.formData.weixinMiniProgramAppSecret && this.formData.weixinMiniProgramOriginalId,
        this.formData.openAppId && this.formData.openName && this.formData.openAppSecret
      ]
    },
    currentStep () {
      return { 0: 0, 3: 1, 5: 2 }[this.active] ?? -1
    }
  },
  created () {
    this.$apis = new Api(this.$axios, this.$message.error)
  },
  mounted () {
    this.$apis.getWxSettingInfos().then(res => {
      this.formData = Object.assign(this.formData, res.data)
    }).catch((response) => {
      Api.$message(this, response)
    })
  },
  methods: {
    onCopyText (text) {
      this.$clipboard(text)
      this.$message.success('复制成功')
    },
    onShouldSetting () {
      this.information.value = {
        name: this.formData.weixinOpenName,
        appid: this.formData.weixinOpenAppId,
        appsecret: this.formData.weixinOpenAppSecret
      }
      this.active = 0
    },
    onViewDoc (status, path) {
      status && window.open(path)
    },
    handlerComplte () {
      this.active = -1
    },
    onClear () {
      this.$confirm('清除微信公众号的绑定内容将无法恢复，同时无法实现转载、提醒、登录等功能，是否继续清除配置？', '清除提醒', {
        confirmButtonText: '清除',
        customClass: 'dialog-mod-alert',
        cancelButtonText: '取消'
      }).then(res => {
        this.$apis.deleteConfig('3,4,5').then(res => {
          this.formData = {
            weixinOpenAppId: null,
            weixinOpenName: null,
            weixinOpenAppSecret: null,
            weixinOpenFileName: null,
            weixinOpenFileContent: null,
            trace_msg_no: null,
            order_msg_no: null,
            weixinPaySendName: null,
            weixinPayMchid: null,
            weixinPaySecret: null,
            weixinMiniProgramAppId: null,
            weixinMiniProgramAppSecret: null,
            weixinMiniProgramOriginalId: null
          }
          this.active = -1
        }).catch((response) => {
          Api.$message(this, response)
        })
      }).catch(res => {
      })
    },
    onSaveInformation () {
      this.$refs.information.$validate((validate) => {
        if (validate) {
          const { name, appid, appsecret } = this.$refs.information.$getValue()
          this.$apis.postWxSetingInfo(3, {
            weixinOpenName: name,
            weixinOpenAppId: appid,
            weixinOpenAppSecret: appsecret
          }).then(res => {
            Object.assign(this.formData, {
              weixinOpenName: name,
              weixinOpenAppId: appid,
              weixinOpenAppSecret: appsecret
            })
            this.$message.success('已保存')

            if (this.formData.trace_msg_no && this.formData.order_msg_no) {
              this.message.value.culeMeaage = this.formData.trace_msg_no
              this.message.value.orderMessage = this.formData.order_msg_no
              this.message.value.orderNewMessage = this.formData.order_stat_no
              this.initPreview()
            }

            if (this.formData.weixinPaySendName && this.formData.weixinPayMchid && this.formData.weixinPaySecret && this.formData.weixinPayFileName) {
              this.wxpay.value = {
                weixinPaySendName: this.formData.weixinPaySendName,
                weixinPayMchid: this.formData.weixinPayMchid,
                file: [{
                  url: this.formData.weixinPayFileName,
                  name: this.formData.weixinPayFileName
                }],
                weixinPaySecret: this.formData.weixinPaySecret
              }
              this.wxpay.data.find(item => item.prop === 'file').bind.props.imgs = [{
                url: this.formData.weixinPayFileName,
                name: this.formData.weixinPayFileName
              }]
            } else {
              if (this.formData.weixinPayFileName) {
                this.wxpay.data.find(item => item.prop === 'file').bind.props.imgs = [{
                  url: this.formData.weixinPayFileName,
                  name: this.formData.weixinPayFileName
                }]
              } else {
                this.wxpay.data.find(item => item.prop === 'file').bind.props.imgs = []
              }
              this.wxpay.value = Object.assign({
                weixinPaySendName: null,
                weixinPayMchid: null,
                weixinPaySecret: null
              }, {
                weixinPaySendName: this.formData.weixinPaySendName,
                weixinPayMchid: this.formData.weixinPayMchid,
                file: this.formData.weixinPayFileName ? [{
                  url: this.formData.weixinPayFileName,
                  name: this.formData.weixinPayFileName
                }] : [],
                weixinPaySecret: this.formData.weixinPaySecret
              })
            }
            this.active = 3
          }).catch((response) => {
            Api.$message(this, response)
          })
        }
      })
    },
    onPrev () {
      if (this.active === 3) {
        this.onShouldSetting()
      } else if (this.active === 4) {
        if (this.formData.weixinPaySendName && this.formData.weixinPayMchid && this.formData.weixinPaySecret) {
          this.wxpay.value.weixinPaySendName = this.formData.weixinPaySendName
          this.wxpay.value.weixinPayMchid = this.formData.weixinPayMchid
          this.wxpay.value.weixinPaySecret = this.formData.weixinPaySecret
        }
        this.active = 3
      } else if (this.active === 5) {
        this.active = 3
      }
    },
    onGotoNext () {
      if (this.active === 0) {
        if (this.formData.weixinPaySendName && this.formData.weixinPayMchid && this.formData.weixinPaySecret && this.formData.weixinPayFileName) {
          this.wxpay.value = {
            weixinPaySendName: this.formData.weixinPaySendName,
            weixinPayMchid: this.formData.weixinPayMchid,
            file: [{
              url: this.formData.weixinPayFileName,
              name: this.formData.weixinPayFileName
            }],
            weixinPaySecret: this.formData.weixinPaySecret
          }
          this.wxpay.data.find(item => item.prop === 'file').bind.props.imgs = [{
            url: this.formData.weixinPayFileName,
            name: this.formData.weixinPayFileName
          }]
        } else {
          if (this.formData.weixinPayFileName) {
            this.wxpay.data.find(item => item.prop === 'file').bind.props.imgs = [{
              url: this.formData.weixinPayFileName,
              name: this.formData.weixinPayFileName
            }]
          } else {
            this.wxpay.data.find(item => item.prop === 'file').bind.props.imgs = []
          }
          this.wxpay.value = Object.assign({
            weixinPaySendName: null,
            weixinPayMchid: null,
            weixinPaySecret: null
          }, {
            weixinPaySendName: this.formData.weixinPaySendName,
            weixinPayMchid: this.formData.weixinPayMchid,
            file: this.formData.weixinPayFileName ? [{
              url: this.formData.weixinPayFileName,
              name: this.formData.weixinPayFileName
            }] : [],
            weixinPaySecret: this.formData.weixinPaySecret
          })
        }
        this.active = 3
      } else if (this.active === 3) {
        if (this.formData.weixinMiniProgramAppId && this.formData.weixinMiniProgramAppSecret && this.formData.weixinMiniProgramOriginalId) {
          this.miniForm.value.weixinMiniProgramAppId = this.formData.weixinMiniProgramAppId
          this.miniForm.value.weixinMiniProgramAppSecret = this.formData.weixinMiniProgramAppSecret
          this.miniForm.value.weixinMiniProgramOriginalId = this.formData.weixinMiniProgramOriginalId
        }
        this.active = 5
      } else if (this.active === 5) {
        this.active = -1
      }
    },
    onSaveApp () {
      this.$refs.app.$submit()
    },
    initPreview () {
      const templateIds = [this.formData.trace_msg_no, this.formData.order_msg_no, this.formData.order_stat_no].filter(item => !!item).map(item => this.$apis.getPreview({ template_id: item }))
      Promise.all(templateIds).then(res => {
        this.$nextTick(() => {
          this.$refs.message.$setValueField('culeMeaageTemplate', res[0].data ? res[0].data.example.split(/\n/) : '')
          this.$refs.message.$setValueField('orderMessageTemplate', res[1].data ? res[1].data.example.split(/\n/) : '')
          this.formData.order_stat_no && this.$refs.message.$setValueField('orderNewMessageTemplate', res[2].data ? res[2].data.example.split(/\n/) : '')
        })
      }).catch((response) => {
        Api.$message(this, response)
      })
    },
    onSaveWxPay () {
      this.$refs.wxpay.$validate((validate) => {
        if (validate) {
          const { weixinPayMchid, weixinPaySecret, weixinPaySendName } = this.$refs.wxpay.$getValue()
          this.$apis.postWxSetingInfo(4, {
            weixinPaySendName,
            weixinPayMchid,
            weixinPaySecret
            // weixinPayFileName: file[0].name,
            // weixinPayFileContent: file[0].url
          }).then(res => {
            Object.assign(this.formData, {
              weixinPaySendName,
              weixinPayMchid,
              weixinPaySecret
              // weixinPayFileName: file[0].name,
              // weixinPayFileContent: file[0].url
            })
            this.$message.success('保存成功')
            if (this.formData.weixinMiniProgramAppId && this.formData.weixinMiniProgramAppSecret && this.formData.weixinMiniProgramOriginalId) {
              this.miniForm.value.weixinMiniProgramAppId = this.formData.weixinMiniProgramAppId
              this.miniForm.value.weixinMiniProgramAppSecret = this.formData.weixinMiniProgramAppSecret
              this.miniForm.value.weixinMiniProgramOriginalId = this.formData.weixinMiniProgramOriginalId
              this.initPreview()
            }
            this.active = 5
          }).catch((response) => {
            Api.$message(this, response)
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.mod-wx-setting{
  padding: 20px;
  position: relative;
  .setting-container{
    width:780px;
    margin: 160px auto 0;
    .el-step__head{
      .el-step__line{
        box-sizing: border-box;
        border-bottom: 2px dashed;
        background-color: transparent;
      }
      &.is-process{
        color: #409eff;
        border-color: #409eff;
        .el-step__line{
          border-color: #c0c4cc;
        }
      }
      &.is-finish{
      color: #409eff;
      border-color: #409eff;
        .el-step__line{
          border-color: #409eff;
        }
        .el-step__icon.is-text {
          background: #409eff;
          color: #fff;
        }
      }
    }
    .el-step__main .el-button.view-doc{
      background: #fff;
      &:active,&:hover,&:focus{
        background: #fff;
        color: #0577ec;
      }
    }
  .el-step__main .el-step__description{
    margin-top: 0;
  }
    .setted{
      color: #63C32E;
    }
    .setting-container-title{
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-style: normal;
      position: relative;
      color: #333;
      .el-icon-question{
        font-size: 14px;
        cursor: pointer;
        padding-left: 2px;
        color: #333;
        position: absolute;
        top:13px;
      }
      .title-icon{
        font-size: 32px;
        text-align: center;
        position: absolute;
        width: 100%;
        display: block;
        top: -78px;
        left: 0px;
        color: #666;
        img{
          width: 44px;
          height: 44px;
          display: inline-block;
        }
      }
    }
    .wx-bind-information{
      margin: 20px 40px;
      text-align: center;
      .wx-bind-information-area{
        background: #f8f8f8;
        box-sizing: border-box;
        padding: 40px 40px;
        margin-bottom: 30px;
        .title{
          color: #333;
          margin: 0;
          text-align: left;
          font-size: 14px;
          line-height: 28px;
          font-weight: bold;
          font-style: normal;
        }
        .list, .list .item{
          list-style: none;
          text-indent: 0px;
          text-align: left;
          padding: 0;
          margin: 0;
          line-height: 28px;
        }
      }
    }
    .wx-form-information{
      margin: 20px 40px;
      .mod-password-eyes {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          color: #8593a7;
        }
      }
      .wx-form-information-form{
        background: #f8f8f8;
        padding: 40px;
      }
      .button-item{
        margin-top: 20px;
        text-align: center;
        .el-button.clear-button{
          color: #999;
          &:hover{
            color: #FF4444;
          }
        }
      }
    }
    .wx-form-config{
      margin: 20px 40px;
      .wx-form-config-form{
        background: #f8f8f8;
        padding: 40px;
      }
      .button-item{
        margin-top: 20px;
        text-align: center;
      }
    }
    .wx-form-message{
      margin: 20px 40px;
      .wx-form-message-form{
        background: #f8f8f8;
        padding: 40px;
        .message-item {
          .message-input{
            width:280px;
            margin-right: 10px;
          }
          .tips{
            color: #999;
          }
        }
      }
      .button-item{
        margin-top: 20px;
        text-align: center;
      }
    }
    .wx-form-wxpay{
      margin: 20px 40px;
      .wx-form-wxpay-form{
        background: #f8f8f8;
        padding: 40px 20px;
      }
      .button-item{
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
.dialog-mod-alert{
  .el-message-box__header{
    border-bottom: 1px solid #cbcdd4;
  }
  .el-message-box__content{
    padding: 30px 15px;
  }
}
</style>
