<template>
	<div :class="['mod-message-box', value.length=== 0 ? 'no-data':'']">
		<div v-if="value.length" class="content-list">
			<div v-for="(item,index) in value" :key="index" class="item">{{ item }}</div>
		</div>
		<div v-if="value.length" class="bottom">
			<span>详情</span>
			<span class="el-icon-arrow-right" />
		</div>
		<p v-if="value.length===0" class="no-data-tips">模板ID或公众号授权有误，请认真检查</p>
	</div>
</template>

<script lang="jsx">
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="less">
.mod-message-box{
  border: 1px solid #efefef;
  box-sizing: border-box;
  &.no-data{
    border: none;
  }
  .no-data-tips{
    text-align: center;
    color: #333;
    font-size: 14px;
    padding: 20px 0;
  }
  .content-list{
    padding: 10px 20px;
    .item{
      line-height: 24px;
      color: #333;
      &:first-child{
        color: #999;
      }
      &:last-child{
        color: #999;
      }
    }
  }
  .bottom{
    border-top: 1px solid #efefef;
    line-height: 40px;
    padding:0 20px;
    display: flex;
    justify-content: space-between;
    .el-icon-arrow-right{
      line-height: 40px;
    }
  }
}
</style>
