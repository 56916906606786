<template>
	<jw-form ref="form" v-bind="form" />
</template>
<script lang="jsx">
import Server from './apis'
export default {
  props: {
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        read: false,
        bind: {
          domProps: {
            autocomplete: 'off'
          },
          props: {
            labelWidth: '182px',
            rules: {
              name: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              },
              appid: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              },
              appsecret: {
                required: true,
                trigger: 'blur',
                message: '不能为空！'
              }
            }
          }
        },
        data: [{
          tag: 'title',
          label: '填写已经过认证的服务号信息'
        }, {
          label: 'APP名称',
          tag: 'el-input',
          prop: 'name',
          bind: {
            attrs: {
              placeholder: '请输入微信APP名称',
              maxlength: 30
            }
          }
        }, {
          label: '开发者ID(AppID)',
          tag: 'el-input',
          prop: 'appid',
          bind: {
            attrs: {
              placeholder: '请输入开发者ID',
              maxlength: 100
            }
          }
        }, {
          label: '开发者密码(AppSecret)',
          prop: 'appsecret',
          render (h, store, value) {
            return <el-input
              value={value.appsecret}
              class='input-password'
              type='text'
              maxlength={100}
              onInput={(e) => { value.appsecret = e }} placeholder='请输入开发者密码'>
            </el-input>
          }
        }],
        value: {
          name: null,
          appid: null,
          appsecret: null
        }
      }
    }
  },
  mounted () {
    this.$apis = new Server()
    this.$refs.form.$setValue({
      name: this.formData.openName || '',
      appid: this.formData.openAppId || '',
      appsecret: this.formData.openAppSecret || ''
    })
  },
  methods: {
    $submit () {
      this.$refs.form.$validate(valid => {
        if (valid) {
          const { name, appid, appsecret } = this.$refs.form.$getValue()
          this.$apis.postWxSetingInfo(6, {
            openName: name,
            openAppId: appid,
            openAppSecret: appsecret
          }).then(res => {
            this.$message.success('已保存')
            this.$emit('complate')
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
